<template>
<div id="projects">
	<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
    <section id="inner-headline">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="pageTitle">{{ data.row.title }}</h2>
                </div>
            </div>
        </div>
    </section>
    <section id="content" style="padding-top: 30px;">
        <div class="container content">
            <div id="property-listings">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="skill-home">
                            <div class="skill-home-solid clearfix">
                                <div class="text-center">
                                    <span class="icons c1">
										<img style="width: 175px; height: 175px;" class="img-responsive" :src="$uploadsURL+'services/'+data.row.image" alt="">
                                    </span>
                                    <div class="box-area">
                                        <div class="media-body fnt-smaller">
                                            <h2 v-html="data.row.title" class="media-heading"></h2>
                                            <p v-html="data.row.description" class="hidden-xs"></p>
                                            <ul v-if="data.gallery.length" id="lightgallery" class="list-unstyled">
												<li v-for="(obj, thumbIndex) in data.gallery" :key="thumbIndex" @click="activeIndex = thumbIndex" class="col-lg-3 col-md-3 col-sm-4 col-xs-6" style="cursor: pointer; padding: 0px;">
                                                    <img :src="obj.url" style="height: 256px;">
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
	<LightGallery :images="data.gallery" :index="activeIndex" :disable-scroll="true" @close="activeIndex = null"/>
</div>
</template>

<script type="text/javascript" scoped>
import { LightGallery } from "vue-light-gallery";

export default{
	components: {
		LightGallery,
	},
	data(){
		return {
			data: {
				row: {
					title: "",
					description: "",
					image: "",
				},
				gallery: [],
			},
			activeIndex: null,
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_service/"+this.$route.params.slug,
		}).then((res) => {
			if(!res.data.row){
				this.$router.push("/services");
			}
			this.data = res.data;
			this.$refs.topProgress.done();
		});
	},
}
</script>

<style type="text/css" scoped>
</style>